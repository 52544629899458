import React, { Component } from 'react'
import {Mutations, JWT, ListStore} from 'storwork-stores'
import { observer } from 'mobx-react'
import swal from 'sweetalert'

const store = new ListStore({
  log: true,
  entities: [{
    path: '',
    alwaysSave: true,
    fields: ['user_id', 'content', 'contact'],
    type: 'Watcher',
    multi: false
  }],
  type: 'watcher'
})

class CreateAlert extends React.Component {

  createAlert = () => {
    swal({
      text: 'Wpisz treść alertu',
      content: 'input',
      button: {
        text: 'Dodaj!',
        closeModal: false
      }
    }).then(name => {
      if (!name) throw null
      let input = {
        content: name,
        group_id: JWT.remember().claim.organization.id,
        user_id: JWT.remember().claim.user.id,
        type: 'alert',
        event: 'alert',
        contact: 'alert'
      }
      Mutations('Watcher', 'create', {input: input})
        .then(id => {
          setTimeout(() => {
            this.props.store.loadRecords()
          }, 1000)
          swal.stopLoading()
          swal.close()
        })
    })
  }

  render () {
    return <div className='py-1 px-2 rounded cursor-pointer float-left' style={{background: '#EBEBEB'}} onClick={this.createAlert}>Dodaj Alert</div>
  }
}

const Alert = observer(props => <div className='m-2 p-2 border border-black float-left'>
  {props.name}
  <div className='clearfix' />
  <i className="float-left fal fa-sm fa-eye"></i>
  <i className="float-right fal fa-sm fa-share-square"></i>
</div>)

const Alerts = observer(class _Alerts extends Component {
  render() {
    return <div className={'p-5'}>
        <CreateAlert store={store} />
        <div className='clearfix' />
        {store.records.map((row, i) => <Alert name={row.content} />)}
        <Alert name='Test' />
        <Alert name='Test2' />
        <Alert name='Test3' />
      </div>
  }
})

export default Alerts


// user_id - lista ID userów nasłuchujących po PRZECINKU (bez spacji)
// content - treść watchera
// contact - email lub coś innego

// mutation {
//   triggerWatcher(watcher_id: 12)
// }
