import React, { Component } from 'react';
import Grid from './Grid'
import {StorworkInit} from 'storwork-stores'

import Alerts from 'Alerts'


const Media = props => <article class="media">
  <figure class="media-left">
    <p class="image is-64x64">
      <img src="https://bulma.io/images/placeholders/128x128.png" />
    </p>
  </figure>
  <div class="media-content">
    <div class="content">
      <p>
        <strong>Krzysztof Sikorski</strong> <small>31 minut temu</small>
        <br />
        Pozyskaliśmy nową dotację
      </p>
    </div>
  </div>
</article>


// type, size, area, direction
class App extends Component {
  render() {
    return <StorworkInit
      loggedIn={<Grid>
        <div type='column' size='50%' area='alerts' direction='left'>
          <Alerts />
        </div>
        <div type='main' area='main' style={{padding: 10}}>
          <Media />
          <Media />
          <Media />
          <Media />
        </div>
      </Grid>} loggedOut={<Grid>
        <div>zaloguj sie gdzies w SW</div>
      </Grid>} />
  }
}

export default App;
